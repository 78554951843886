.traveller {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    overflow: scroll;
}

.traveller-info {
    margin-bottom: 20px;
}

.traveller-info p {
    margin: 5px 0;
}

.journeys {
    margin-top: 20px;
}

.journey {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
}

.journey p {
    margin: 3px 0;
}
