.App{
    height: 100vh;
    /* overflow: hidden; */
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
/* .handleHeight{
    height: calc(100vh-52px);
} */
.svg-size span{
    color: white;
}
:root{
    --primary-theme-color:#a52b3f;
    --primary-bg-color-dark: #131313;
    --primary-text-color-dark: #cacaca;
    --primary-border-color-dark: #fcfcfc;
}

.svg-size svg{
    font-size: 1.8rem;
    color: var(--primary-theme-color);
}
.css-1rwymis-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon{
    color: var(--primary-border-color-dark);
}

.MuiOutlinedInput-root{
    color: var(--primary-border-color-dark);
}
.MuiOutlinedInput-input.Mui-disabled{
    -webkit-text-fill-color: #7e7d7d !important;
}
.MuiOutlinedInput-input{
    color: var(--primary-border-color-dark) !important;
}
.MuiOutlinedInput-notchedOutline {
    border-color: var(--primary-border-color-dark) !important
}
.MuiOutlinedInput-root.MuiFocused .MuiOutlinedInput-notchedOutline{
    border-color: var(--primary-theme-color) !important
}
.MuiList-root.MuiMenu-list{
    background-color: var(--primary-border-color-dark);
}

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:active{
      -webkit-background-clip: text;
      -webkit-text-fill-color: white;
      transition: background-color 5000s ease-in-out 0s;
      box-shadow: inset 0 0 20px 20px #23232329;
  }

